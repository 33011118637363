.account-page {
  display: flex;
  flex-direction: column;
  flex: 1;

  .tabs {
    display: flex;
    padding: 10px;
    padding-bottom: 0px;
    background: white;

    a {
      display: block;
      padding: 15px;
      border-radius: 4px 4px 0px 0px;
      cursor: pointer;
      margin-right: 10px;

      &:hover {
        background: #F9F9F9;
      }

      &.active {
        background: #f4f6f8;
      }
    }
  }

  .tab {
    padding: 20px;
  }
}
.iframe {
  max-width: 1160px;
  width: 100%;
  margin: 0px auto;
  background: white;
  background: #f3f3f3;
  display: flex;
}
.iframe .menu {
  margin-left: 20px;
  background: white;
  width: 25%;
  padding: 20px;
  flex: 0 0 auto;
}
.iframe .menu a:not(.light) {
  text-transform: none;
  display: inline-block;
  color: #fff;
  background-color: #4e0755;
  padding: 4px 0.9em 4px 1.2em;
  margin: 4px 0;
  border: none;
  font-weight: 400;
  border-radius: 0 27px 27px 0;
  text-decoration: none;
  font-size: 16px;
  display: block;
  line-height: 22.8571px;
  cursor: pointer;
}
.iframe .page {
  background: white;
  flex: 1;
  padding: 20px;
}
.iframe .page .classe {
  display: flex;
}
.iframe .page .classe .left {
  width: 175px;
  margin-right: 40px;
}
.iframe .page .classe .left .image {
  width: 100%;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.iframe .page .classe .left .image .dates {
  background: white;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 7px;
  text-align: center;
  line-height: 1.05;
  font-weight: 700;
  font-size: 14.4px;
}
.iframe .page .classe .left .image .dates .day {
  color: #eaaa26;
  font-size: 19.2px;
}
.iframe .page .classe .left .image .dates .separator {
  height: 1px;
  width: 10px;
  line-height: 1.1;
  font-size: 0.9em;
  margin: 6px auto;
  background-color: #000;
  border-top: 1px solid #eee;
  border: 0;
}
.iframe .page .classe .left .warning {
  text-transform: none;
  display: inline-block;
  color: red;
  cursor: not-allowed;
  padding: 4px 0.9em 4px 1.2em;
  margin: 4px 0;
  border: none;
  font-weight: 700;
  border-radius: 0 27px 27px 0;
  text-decoration: none;
  font-size: 16px;
  display: block;
  line-height: 22.8571px;
}
.iframe .page .classe .left button {
  width: 100%;
  text-transform: none;
  display: inline-block;
  color: #fff;
  background-color: #4e0755;
  padding: 4px 0.9em 4px 1.2em;
  margin: 4px 0;
  border: none;
  font-weight: 400;
  border-radius: 0 27px 27px 0;
  text-decoration: none;
  font-size: 16px;
  display: block;
  line-height: 22.8571px;
  cursor: pointer;
}
.iframe .page .classe .infos {
  flex: 1;
}
.iframe .page .classe .infos .over-title {
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.1em;
  margin-bottom: 0;
  font-family: "open sans", "helvetica neue", Helvetica, Arial, sans-serif;
}
.iframe .page .classe .infos .title {
  font-size: 36px;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 700;
  color: #4e0755;
  margin-bottom: 20px;
  margin-top: 3px;
  font-family: "open sans", "helvetica neue", Helvetica, Arial, sans-serif;
}
.iframe .page .classe .infos .title::after {
  content: "";
  display: block;
  margin: 0.9em 0 5%;
  width: 30%;
  border-bottom: 1px solid;
}
.iframe .page .classe .infos .sub-title {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  font-family: "open sans", "helvetica neue", Helvetica, Arial, sans-serif;
}
.iframe .page .classe .infos hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0px;
  border-top: 1px solid #eee;
  height: 0px;
}
.iframe .page .classe .infos .content {
  min-height: 400px;
  font-family: "open sans", "helvetica neue", Helvetica, Arial, sans-serif;
}
.iframe .page .classe .infos .content h3 {
  font-size: 28px;
  font-weight: 400;
  margin-top: 35px;
  line-height: 1.4;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
}
.iframe .page .classe .infos .content p {
  margin-top: 25px;
}
.iframe .page .classe .infos .content p.intro {
  font-size: 20px;
  color: #4e0755;
  margin-top: 0.3em;
  margin-bottom: 2em;
}
.iframe .page .classe .infos .content em {
  margin-top: 25px;
  font-style: italic;
}
.iframe .page .professor .with {
  font-size: 28px;
  font-weight: 400;
  margin-top: 135px;
  line-height: 1.4;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
}
.iframe .page .professor .wrapper {
  background: #f7f7f7;
  display: flex;
  margin-top: 10px;
}
.iframe .page .professor .wrapper .image {
  width: 170px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 112px;
  flex: 0 0 auto;
}
.iframe .page .professor .wrapper .infos {
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.iframe .page .professor .wrapper .infos .top .name {
  color: #4e0755;
  text-transform: uppercase;
  font-weight: 700;
}
.iframe .page .professor .wrapper .infos .top .quality {
  font-size: 0.875em;
  font-style: normal;
  font-weight: 400;
  height: 40px;
  color: #777;
}
.iframe .page .professor .wrapper .infos .bottom a {
  font-size: 0.935em;
  color: #000;
  display: inline-block;
  padding-right: 15px;
  font-weight: 600;
  color: #4e0755;
  font-size: 14px;
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .iframe .page .classe {
    flex-direction: column-reverse;
  }
  .iframe .page .classe .left {
    width: 100%;
    margin-top: 20px;
  }
  .iframe .page .menu {
    width: 33%;
  }
}
@media screen and (max-width: 768px) {
  .iframe {
    flex-direction: column;
  }
  .iframe .page .classe {
    flex-direction: column-reverse;
  }
  .iframe .page .classe .left {
    width: 100%;
    margin-top: 20px;
  }
  .iframe .menu {
    margin: 0px;
    width: 100%;
    margin-top: 20px;
  }
}
form.activate {
  width: 100%;
  max-width: 350px;
  margin: 0px;
  background: white;
  border-radius: 4px;
  padding: 20px;
}
form.activate > svg {
  width: 160px;
  margin: 40px auto;
  display: block;
}
form.activate input {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 8px;
  width: 100%;
  margin-bottom: 10px;
  font-size: 1.2em;
  outline: none;
}
form.activate input:focus {
  border-color: #5dd0f3;
}
form.activate a:not(.btn) {
  text-decoration: none;
  display: block;
  text-align: center;
  cursor: pointer;
  background: white;
  border-radius: 3px;
  padding: 10px 0px;
  font-size: 0.9em;
}
form.activate a:not(.btn).forgot-password {
  text-align: right;
  padding: 0px;
  padding-right: 5px;
  margin-bottom: 20px;
  margin-top: -5px;
}
form.activate a:not(.btn).forgot-password:hover {
  background: none;
}
form.activate a:not(.btn):hover {
  background: #daf7ff;
}
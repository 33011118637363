.dashboard-page {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.dashboard-page .border-top {
  position: relative;
}
.dashboard-page .border-top::after {
  content: " ";
  position: absolute;
  top: 0px;
  left: 20%;
  width: 60%;
  height: 1px;
  background: rgba(79, 29, 80, 0.2);
}
.dashboard-page .border-left {
  position: relative;
}
.dashboard-page .border-left::after {
  content: " ";
  position: absolute;
  top: 10%;
  left: 0px;
  width: 1px;
  height: 80%;
  background: rgba(79, 29, 80, 0.2);
}
.dashboard-page .split {
  flex: 1;
  display: flex;
}
.dashboard-page .split .image {
  flex: 1;
  background: url(/images/1x/dashboard-background.png) no-repeat center center;
  background-size: cover;
}
.dashboard-page .split .infos {
  flex: 1;
  padding: 20px;
}
.dashboard-page .split .infos .box .data {
  flex: 1;
  text-align: right;
  padding-left: 20px;
}

@media screen and (max-width: 650px) {
  .dashboard-page .split {
    flex-direction: column;
  }
  .dashboard-page .split .infos {
    flex-direction: column;
  }
  .dashboard-page .split .infos .box {
    flex-direction: column;
  }
  .dashboard-page .split .infos .box .data {
    margin-top: 40px;
    flex: 1;
    text-align: left;
    padding-left: 0px;
  }
  .dashboard-page .split .infos .box .data.border-left::after {
    display: none;
  }
}
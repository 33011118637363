.tabs-component {
  display: flex;
  flex-direction: row;
  padding: 0px 20px;
  border-bottom: 1px solid rgba(79, 29, 80, 0.2);
  padding-top: 5px;

  &.sub {
    background: #F9F9F9;
  }

  &.no-padding {
    padding: 0px;
  }

  & > * + * {
    margin-left: 20px;
  }

  .tab-component {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    cursor: pointer;
    border-radius: 4px 4px 0px 0px;
    color: grey;
    white-space: nowrap;

    &:hover {
      color: #007bff;
    }

    &.active {
      background: none;
      padding-bottom: 9px;
      border-bottom: 2px solid #4F1D50;
      //background: #007bff;
      color: #4F1D50;
      margin-bottom: -1px;
    }
  }
}

@media screen and (max-width: 650px) {
  .tabs {
    max-width: 100vw;
    overflow-x: auto;
    overflow-y: hidden;
    border: none;
  }
}
.user-layout .ressource-page.registration-page {
  padding: 20px;
  display: flex;
  flex-direction: row;
}
.user-layout .ressource-page.registration-page .infos {
  flex: 1;
  margin-right: 20px;
}

@media screen and (max-width: 650px) {
  .user-layout .ressource-page.registration-page {
    flex-direction: column;
  }
  .user-layout .ressource-page.registration-page .infos {
    flex: 0 0 auto;
  }
}
.form-image-component .wrapper {
  border-radius: 8px;
  border: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}
.form-image-component .wrapper .image {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #F9F9F9;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.form-image-component .wrapper .image .remove {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 8px;
}
.form-image-component .wrapper .image .remove:hover {
  background-color: #E9E9E9;
  border: 1px solid white;
}
.form-image-component .wrapper .image .remove svg {
  width: 20px;
  height: 20px;
}
.form-image-component .wrapper .image:hover .remove {
  display: flex;
}
.checkout-page {
  max-width: 1160px;
  width: 100%;
  margin: 0px auto;

  .head {
    padding: 20px 0px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;

    .plateform {
      display: flex;
      align-items: center;
      flex: 1;

      img {
        margin-right: 40px;
        width: 100px;
      }
  
      .name {
        flex: 1;
        font-size: 1.8em;
  
        .sub {
          font-size: 0.8em;
        }
      }
    }

    .identity {
      flex: 0 0 auto;
      text-align: right;
    }
  }



  .link {
    text-transform: none;
    display: inline-block;
    color: #fff;
    background-color: #4e0755;
    padding: 4px .9em 4px 1.2em;
    margin: 4px 0;
    border: none;
    font-weight: 400;
    border-radius: 27px;
    text-decoration: none;
    font-size: 16px;
    display: block;
    line-height: 22.8571px;
    cursor: pointer;
  }

  .message {
    padding: 20px 0px;
  }

  .page {
    display: flex;

    .prices {
      width: 25%;
      margin-left: 20px;
    }

    .content {
      flex: 1;
      padding-bottom: 50px;
    
      .classe {
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 8px;
        margin-top: 20px;
    
        .name {
          margin-bottom: 5px;
          font-weight: bold;
        }

        .package {
          padding: 20px;
          border: 1px solid #ccc;
          border-radius: 8px;
          display: flex;
          align-items: center;
          cursor: pointer;
  
          &:hover {
            background: #494949;
          }
        }
      }

      .form-divid {
        display: flex;

        .separator {
          width: 20px;
        }
      }
    
      .option {
        cursor: pointer;
        border-radius: 8px;
        border: 1px solid #ccc;
        padding: 20px;
        margin-top: 10px;
        display: flex;
    
        &:hover {
          border-color: blue;
        }
    
        &.selected {
          border-color: blue;
          background: #eeeeff;
        }
      }
    
      form {
        label {
    
        }
    
        .input {
          border-radius: 8px;
          border: 1px solid #ccc;
          padding: 10px;
          font-size: 1em;
          margin-bottom: 10px;
        }
    
        button {
        }
      }
    }
  }

}

@media screen and (max-width: 768px) { 
  .checkout-page {
    .head {
      flex-direction: column;
      align-items: inherit;
      flex-wrap: wrap;
      padding: 0px;

      .plateform {
        padding: 20px;

        .name {
          flex: auto;
        }
  
        img {
          width: 50px;
        }
      }

      .identity {
        padding: 20px;
        padding-top: 20px;
        text-align: left;
        border-top: 1px solid #ccc;
      }
    }

    .message {
      padding: 20px;
    }

    .threetime {
      flex-direction: column;
    }

    .page {
      flex-direction: column-reverse;

      .content {
        flex: 1;
        padding: 0px 20px;
        padding-bottom: 50px;
      }

      .prices {
        width: inherit;
        margin: 0px;
        background: #D9D9D9;
        padding: 0px 20px;
      }

      .form-divid {
        flex-direction: column;

        .separator {
          height: 20px;
        }
      }
    }
  }
}
.sinod {

  .header {
    max-width: 1160px;
    width: 100%;
    margin: 0px auto;
    background: white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 300px;
    }

    .identity {
      text-align: right;
    }
  }

  .bar {
    background: #029583;
    height: 45px;
  }

  .page {
    max-width: 1160px;
    width: 100%;
    margin: 0px auto;
    background: white;
    padding: 20px;

    .no-classe {
      text-align: center;
      color: grey;
      padding: 50px 0px;
      font-size: 1.2em;
    }

    .classe {
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 8px;

      .infos {

        .title {
          font-weight: 700;
          margin-bottom: 10px;
        }

        .category {
          margin-bottom: 10px;
        }

        .professors {
          margin-bottom: 10px;
        }

        .first-classe {
          //margin-bottom: 10px;
        }
      }
    }

    .process-sinod {
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 8px;
    }

    .packages {

      &>* + * {
        margin-top: 20px;
      }

      .package {
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 8px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          background: #E9E9E9;
        }
      }
    }

    .tarifs {

      .plans {
        margin: 20px 0px;
        display: flex;

        &> * + * {
          margin-left: 20px;
        }

        .plan {
          flex: 1;
          text-align: center;
          border-radius: 8px;
          cursor: pointer;
          border: 1px solid #ccc;
          padding: 20px 0px;

          &:hover {
            background: #F9F9F9;
            border-color: grey;
          }

          &.active {
            border-color: green;
            background-color: #a8ffa8;
          }

          .number {
            margin-top: 10px;
            font-weight: 700;
            font-size: 1.2em;
          }
        }
      }

      .document {
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 8px;
      }
    }

    .form-divid {
      display: flex;

      .separator {
        width: 20px;
      }
    }

    form.paiment {
      label {
  
      }
  
      .input {
        border-radius: 8px;
        border: 1px solid #ccc;
        padding: 10px;
        font-size: 1em;
        margin-bottom: 10px;
      }
  
      button {
      }
    }
  }
}

@media screen and (max-width: 992px) { 
  .sinod {

    .header {
      flex-direction: column;
      padding: 0px;
      padding-top: 20px;

      .identity {
        padding: 20px;
        margin-top: 20px;
        text-align: left;
        width: 100%;
        border-top: 1px solid #029583;
      }
    }

    .page {

      .tarifs {

        .plans {
          flex-direction: column;

          &> * + * {
            margin-top: 20px;
            margin-left: 0px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) { 
  .sinod {
  }
}
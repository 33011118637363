.classe-planning {
  display: flex;
  flex: 1;
}
.classe-planning > * + * {
  border-left: 1px solid #ccc;
}
.classe-planning .day {
  flex: 1;
}
.classe-planning .day .label {
  padding: 20px 10px;
  background: #F9F9F9;
  text-align: center;
  font-weight: 500;
}
.classe-planning .day .schedule {
  display: flex;
  border-radius: 8px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  padding: 10px 5px;
  margin: 5px;
}
.classe-planning .day .schedule .text {
  flex: 1;
  text-align: center;
  cursor: pointer;
  font-size: 0.9em;
}
.classe-planning .day button {
  margin-top: 20px;
}
*, *::before, *::after {
  box-sizing: border-box;
}

a, button {
  color: #007bff;
  text-decoration: none;
}
a.center, button.center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
a.ressource, button.ressource {
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #ccc;
  background: white;
  display: block;
  position: relative;
}
a.ressource:hover, button.ressource:hover {
  background: #E9E9E9;
}
a.ressource::after, button.ressource::after {
  content: ">";
  position: absolute;
  top: 4px;
  right: 10px;
  font-size: 1.4em;
  font-weight: 700;
  color: black;
}
a.btn, button.btn {
  color: white;
  font-weight: 500;
  font-size: 1em;
  cursor: pointer;
  background: none;
  border: none;
  padding: 10px;
  border-radius: 8px;
  outline: none;
  background: #520b4e;
  color: white;
}
a.btn:hover, button.btn:hover {
  background-color: #863781;
}
a.btn.center, button.btn.center {
  margin: 0px auto;
}
a.btn.clear, button.btn.clear {
  background: white;
  font-weight: 500;
  padding: 5px;
}
a.btn.clear:hover, button.btn.clear:hover {
  background-color: #ceddff;
}
a.btn.grey, button.btn.grey {
  background: none;
  color: grey;
  font-size: 0.8em;
  padding: 5px 10px;
}
a.btn.grey:hover, button.btn.grey:hover {
  color: #007bff;
  background-color: #F9F9F9;
}
a.btn.light, button.btn.light {
  background: white;
  font-weight: 500;
  padding: 5px 0px;
  color: blue;
}
a.btn.light:hover, button.btn.light:hover {
  color: #0b5ed7;
}
a.btn.red, button.btn.red {
  color: #df3e3e;
  background: #ffcece;
}
a.btn.small, button.btn.small {
  padding: 5px;
}
a.btn.full, button.btn.full {
  display: block;
  width: 100%;
  text-align: center;
}
a.btn:disabled, button.btn:disabled {
  color: grey;
  cursor: not-allowed;
  background: #D9D9D9;
}

.list-page .filters {
  margin: 40px;
  margin-bottom: 0px;
  display: flex;
}
.list-page .filters > * + * {
  margin-left: 20px;
}
.list-page .filters .filter.full {
  flex: 1;
}

h3 {
  color: #4F1D50;
}

.fields-table .field-component {
  margin: 0px;
  padding: 20px;
}
.fields-table > *:nth-child(even) {
  background: #F4F4F4;
}
.fields-table > *:nth-child(odd) {
  background: #EDEDED;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body #root {
  display: flex;
  flex-direction: column;
}
body code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
form.login {
  width: 100%;
  max-width: 350px;
  margin: 0px;
}
form.login img {
  margin: 20px 0px;
}
form.login input {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  margin-bottom: 10px;
  font-size: 1.2em;
  outline: none;
}
form.login input:focus {
  border-color: #5dd0f3;
}
form.login .forgot-password {
  text-decoration: none;
  display: block;
  text-align: center;
  cursor: pointer;
  background: white;
  border-radius: 3px;
  padding: 10px 0px;
  font-size: 0.9em;
  text-align: right;
  padding: 0px;
  padding-right: 5px;
  margin-bottom: 20px;
  margin-top: -5px;
}
form.login .forgot-password:hover {
  background: none;
}
.classe-planning {
  display: flex;
  flex: 1;

  &> * + * {
    border-left: 1px solid #ccc;
  }

  .day {
    flex: 1;

    .label {
      padding: 20px 10px;
      background: #F9F9F9;
      text-align: center;
      font-weight: 500;
    }

    .schedule {
      display: flex;
      border-radius: 8px;
      margin-bottom: 5px;
      border: 1px solid #ccc;
      padding: 10px 5px;
      margin: 5px;

      .text {
        flex: 1;
        text-align: center;
        cursor: pointer;
        font-size: 0.9em;
      }
    }

    button {
      margin-top: 20px;
    }
  }
}
.pay-page {
  width: 100%;
  height: 100vh;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #D9D9D9;
}
.pay-page .wrapper {
  background: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 450px;
}
.pay-page .head {
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
}
.pay-page .head .plateform {
  display: flex;
  align-items: center;
  flex: 1;
}
.pay-page .head .plateform img {
  margin-right: 40px;
  width: 100px;
}
.pay-page .head .plateform .name {
  flex: 1;
  font-size: 1.8em;
}
.pay-page .head .plateform .name .sub {
  font-size: 0.8em;
}
.pay-page .head .identity {
  flex: 0 0 auto;
  text-align: right;
}
.pay-page .link {
  text-transform: none;
  display: inline-block;
  color: #fff;
  background-color: #4e0755;
  padding: 4px 0.9em 4px 1.2em;
  margin: 4px 0;
  border: none;
  font-weight: 400;
  border-radius: 27px;
  text-decoration: none;
  font-size: 16px;
  display: block;
  line-height: 22.8571px;
  cursor: pointer;
}
.pay-page form .box {
  display: flex;
}
.pay-page form .box > * + * {
  margin-left: 20px;
}
.pay-page form .box > * {
  flex: 1;
}
.pay-page form .input {
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 1em;
  margin-bottom: 10px;
}
.pay-page form button {
  width: 100%;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .pay-page {
    background: white;
    display: block;
  }
  .pay-page .head {
    flex-direction: column;
    align-items: inherit;
    flex-wrap: wrap;
    padding: 0px;
  }
  .pay-page .head .plateform {
    padding: 20px;
  }
  .pay-page .head .plateform .name {
    flex: auto;
  }
  .pay-page .head .plateform img {
    width: 50px;
  }
  .pay-page .head .identity {
    padding: 20px;
    padding-top: 20px;
    text-align: left;
    border-top: 1px solid #ccc;
  }
  .pay-page .message {
    padding: 20px;
  }
  .pay-page .threetime {
    flex-direction: column;
  }
  .pay-page .page {
    flex-direction: column-reverse;
  }
  .pay-page .page .content {
    flex: 1;
    padding: 0px 20px;
    padding-bottom: 50px;
  }
  .pay-page .page .prices {
    width: inherit;
    margin: 0px;
    background: #D9D9D9;
    padding: 0px 20px;
  }
  .pay-page .page .form-divid {
    flex-direction: column;
  }
  .pay-page .page .form-divid .separator {
    height: 20px;
  }
}
.iframelist {
  max-width: 1160px;
  width: 100%;
  margin: 0px auto;
  background: white;
  background: #f3f3f3;
  display: flex;
}
.iframelist .menu {
  margin-left: 20px;
  background: white;
  width: 25%;
  padding: 20px;
  flex: 0 0 auto;
  font-family: "open sans", "helvetica neue", Helvetica, Arial, sans-serif;
}
.iframelist .menu a:not(.light) {
  text-transform: none;
  display: inline-block;
  color: #fff;
  background-color: #4e0755;
  padding: 4px 0.9em 4px 1.2em;
  margin: 4px 0;
  border: none;
  font-weight: 400;
  border-radius: 0 27px 27px 0;
  text-decoration: none;
  font-size: 16px;
  display: block;
  line-height: 22.8571px;
  cursor: pointer;
}
.iframelist .page {
  background: white;
  flex: 1;
  padding: 20px;
  font-family: "open sans", "helvetica neue", Helvetica, Arial, sans-serif;
}
.iframelist .page .sub {
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.1em;
  margin-bottom: 30px;
}
.iframelist .page > .title {
  font-size: 36px;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 700;
  color: #4e0755;
  margin-bottom: 30px;
}
.iframelist .page .desc {
  font-size: 20px;
  color: #4e0755;
  margin-top: 0.3em;
  margin-bottom: 2em;
}
.iframelist .page > a {
  text-transform: none;
  display: inline-block;
  color: #fff;
  background-color: #4e0755;
  padding: 4px 0.9em 4px 1.2em;
  margin: 4px 0;
  border: none;
  font-weight: 400;
  border-radius: 0 27px 27px 0;
  text-decoration: none;
  font-size: 16px;
  line-height: 22.8571px;
  cursor: pointer;
}
.iframelist .page .date {
  font-size: 22px;
  color: #4e0755;
  font-weight: 400;
  line-height: 1.4;
  margin-top: 25px;
  margin-bottom: 5px;
}
.iframelist .page .category > .title {
  font-size: 28px;
  font-weight: 400;
  margin-top: 35px;
  line-height: 1.4;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.iframelist .page .category .sub-category {
  margin-top: 25px;
  margin-bottom: 10px;
  margin-left: 20px;
}
.iframelist .classes {
  margin-left: 20px;
  margin-top: 10px;
}
.iframelist .classes .link {
  display: block;
  padding: 5px 0;
  color: #4e0755;
  font-weight: 600;
  text-decoration: none;
  text-decoration: underline;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .iframelist {
    flex-direction: column;
  }
  .iframelist .menu {
    width: inherit;
    margin: 0px;
    margin-top: 20px;
  }
}
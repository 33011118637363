.classe-page {

  .split {
    padding: 20px;
    display: flex;
    flex-direction: row;
  }

  .infos {
    flex: 1;
    margin-right: 20px;
  }
}

@media screen and (max-width: 650px) {
  .classe-page {

    .split {
      flex-direction: column;
  
      .infos {
        flex: 0 0 auto;
      }
    }
  }
}
.field-component {
  display: flex;
  margin-bottom: 20px;
}
.field-component > .label {
  width: 150px;
  font-weight: 500;
  flex: 0 0 auto;
  text-align: right;
  margin-right: 20px;
}
.field-component > .label.box {
  margin-top: 10px;
}
.field-component > .value {
  flex: 1;
}

@media screen and (max-width: 650px) {
  .field-component {
    flex-direction: column;
  }
  .field-component > .label {
    text-align: left;
    margin-bottom: 5px;
  }
}
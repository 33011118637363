.head-component {
  display: flex;
  align-items: center;
  padding-bottom: 0px;
}
.head-component.sub {
  background: #F9F9F9;
}
.head-component.sub h1 {
  font-size: 1.6em;
  padding: 4px 0px;
}
.head-component .loader-component {
  flex: 1;
  justify-content: flex-start;
  padding: 5px 0px;
}
.head-component h1 {
  margin: 0px;
  flex: 1;
  background: #4F1D50;
  padding: 30px 20px;
  color: white;
}
.head-component h1 .prefix {
  color: #FCFCFC;
}
.head-component .actions {
  display: flex;
  align-items: center;
}
.head-component .actions > * + * {
  margin-left: 20px;
}
.head-component .actions form {
  display: flex;
  align-items: center;
}
.head-component .actions form > * + * {
  margin-left: 20px;
}
.dashboard-page {
  flex: 1;
  display: flex;
  flex-direction: column;

  .border-top {
    position: relative;

    &::after {
      content: ' ';
      position: absolute;
      top: 0px;
      left: 20%;
      width: 60%;
      height: 1px;
      background: rgba(79, 29, 80, 0.2);
    }
  }

  .border-left {
    position: relative;

    &::after {
      content: ' ';
      position: absolute;
      top: 10%;
      left: 0px;
      width: 1px;
      height: 80%;
      background: rgba(79, 29, 80, 0.2);
    }
  }

  .split {
    flex: 1;
    display: flex;

    .image {
      flex: 1;
      background: url(/images/1x/dashboard-background.png) no-repeat center center;
      background-size: cover;
    }

    .infos {
      flex: 1;
      padding: 20px;

      .box {

        .data {
          flex: 1;
          text-align: right;
          padding-left: 20px;
        }
      }
    }
  }
}



@media screen and (max-width: 650px) { 
  .dashboard-page {

    .split {
      flex-direction: column;

      .infos {
        flex-direction: column;

        .box {
          flex-direction: column;

          .data {
            margin-top: 40px;
            flex: 1;
            text-align: left;
            padding-left: 0px;

            &.border-left {
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }

}
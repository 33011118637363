.checkout-page {
  max-width: 1160px;
  width: 100%;
  margin: 0px auto;
}
.checkout-page .head {
  padding: 20px 0px;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
}
.checkout-page .head .plateform {
  display: flex;
  align-items: center;
  flex: 1;
}
.checkout-page .head .plateform img {
  margin-right: 40px;
  width: 100px;
}
.checkout-page .head .plateform .name {
  flex: 1;
  font-size: 1.8em;
}
.checkout-page .head .plateform .name .sub {
  font-size: 0.8em;
}
.checkout-page .head .identity {
  flex: 0 0 auto;
  text-align: right;
}
.checkout-page .link {
  text-transform: none;
  display: inline-block;
  color: #fff;
  background-color: #4e0755;
  padding: 4px 0.9em 4px 1.2em;
  margin: 4px 0;
  border: none;
  font-weight: 400;
  border-radius: 27px;
  text-decoration: none;
  font-size: 16px;
  display: block;
  line-height: 22.8571px;
  cursor: pointer;
}
.checkout-page .message {
  padding: 20px 0px;
}
.checkout-page .page {
  display: flex;
}
.checkout-page .page .prices {
  width: 25%;
  margin-left: 20px;
}
.checkout-page .page .content {
  flex: 1;
  padding-bottom: 50px;
}
.checkout-page .page .content .classe {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 20px;
}
.checkout-page .page .content .classe .name {
  margin-bottom: 5px;
  font-weight: bold;
}
.checkout-page .page .content .classe .package {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.checkout-page .page .content .classe .package:hover {
  background: #494949;
}
.checkout-page .page .content .form-divid {
  display: flex;
}
.checkout-page .page .content .form-divid .separator {
  width: 20px;
}
.checkout-page .page .content .option {
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 20px;
  margin-top: 10px;
  display: flex;
}
.checkout-page .page .content .option:hover {
  border-color: blue;
}
.checkout-page .page .content .option.selected {
  border-color: blue;
  background: #eeeeff;
}
.checkout-page .page .content form .input {
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 1em;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .checkout-page .head {
    flex-direction: column;
    align-items: inherit;
    flex-wrap: wrap;
    padding: 0px;
  }
  .checkout-page .head .plateform {
    padding: 20px;
  }
  .checkout-page .head .plateform .name {
    flex: auto;
  }
  .checkout-page .head .plateform img {
    width: 50px;
  }
  .checkout-page .head .identity {
    padding: 20px;
    padding-top: 20px;
    text-align: left;
    border-top: 1px solid #ccc;
  }
  .checkout-page .message {
    padding: 20px;
  }
  .checkout-page .threetime {
    flex-direction: column;
  }
  .checkout-page .page {
    flex-direction: column-reverse;
  }
  .checkout-page .page .content {
    flex: 1;
    padding: 0px 20px;
    padding-bottom: 50px;
  }
  .checkout-page .page .prices {
    width: inherit;
    margin: 0px;
    background: #D9D9D9;
    padding: 0px 20px;
  }
  .checkout-page .page .form-divid {
    flex-direction: column;
  }
  .checkout-page .page .form-divid .separator {
    height: 20px;
  }
}
.account-page {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.account-page .tabs {
  display: flex;
  padding: 10px;
  padding-bottom: 0px;
  background: white;
}
.account-page .tabs a {
  display: block;
  padding: 15px;
  border-radius: 4px 4px 0px 0px;
  cursor: pointer;
  margin-right: 10px;
}
.account-page .tabs a:hover {
  background: #F9F9F9;
}
.account-page .tabs a.active {
  background: #f4f6f8;
}
.account-page .tab {
  padding: 20px;
}
.field-component {
  display: flex;
  margin-bottom: 20px;

  &>.label {
    width: 150px;
    font-weight: 500;
    flex: 0 0 auto;
    text-align: right;
    margin-right: 20px;
    
    &.box {
      margin-top: 10px;
    }
  }

  &>.value {
    flex: 1;
  }
}



@media screen and (max-width: 650px) {
  .field-component {
    flex-direction: column;
  
    &>.label {
      text-align: left;
      margin-bottom: 5px;
    }
  }
}
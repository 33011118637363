.etipi {
  font-family: 'Source Sans Pro', sans-serif !important;
  color: #fff;
  background-color: #191919;
  min-height: 100vh;

  button, a.btn {
    align-self: center;
    background-color: #f0c990;
    border: 1px solid #f0c990;
    border-radius: 100em;
    color: white;
    cursor: pointer;
    font-weight: bold;
    margin-top: 1.9em;
    padding: 0.55rem 2.5rem;
    text-transform: uppercase;
    transition: 0.2s;
    width: 100%;
    white-space: normal;
    margin: 2em 0;
    width: auto;

    &:hover {
      background-color: white;
      color: #f0c990;
    }
  }

  .header {
    max-width: 1160px;
    width: 100%;
    margin: 0px auto;
    padding: 0px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 300px;
    }

    .identity {
      text-align: right;
    }
  }

  .bar {
    background: #d29e3a;
    height: 45px;
  }

  h3 {
    display: flex;
    align-items: center;
    gap: 12px;
    color: white;

    .bullet {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #d29e3a;
      color: white;
      font-size: 1.2em;
      font-weight: bold;
      width: 35px;
      height: 35px;
    }
  }

  .page {
    max-width: 1160px;
    width: 100%;
    margin: 0px auto;
    padding: 20px 50px;

    .no-classe {
      text-align: center;
      color: grey;
      padding: 50px 0px;
      font-size: 1.2em;
    }

    .classe {
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 8px;

      .infos {

        .title {
          font-weight: 700;
        }

        .category {
          margin-bottom: 10px;
        }

        .professors {
          margin-bottom: 10px;
        }

        .first-classe {
          //margin-bottom: 10px;
        }
      }
    }

    .sessions {
      border: 1px solid #ccc;
      border-radius: 8px;
      overflow: hidden;

      &> * + * {
        border-top: 1px solid #ccc;
      } 

      .session {
        padding: 20px;
        cursor: pointer;

        &:hover {
          background: #F9F9F9;
        }

        &.active {
          border-color: #d29e3a;
          background-color: #efd6a7;
          color: black;
        }
      }

    }

    .packages {

      &>* + * {
        margin-top: 20px;
      }

      .package {
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 8px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          background: #494949;
        }
      }
    }

    .tarifs {

      .plans {
        margin: 20px 0px;
        display: flex;

        &> * + * {
          margin-left: 20px;
        }

        .plan {
          flex: 1;
          text-align: center;
          border-radius: 8px;
          cursor: pointer;
          border: 1px solid #ccc;
          padding: 20px 0px;

          &:hover {
            background: #F9F9F9;
            border-color: grey;
            color: black;
          }

          &.active {
            border-color: #d29e3a;
            background-color: #efd6a7;
            color: black;

            &:hover {
              color: black;
            }
          }

          .number {
            margin-top: 10px;
            font-weight: 700;
            font-size: 1.2em;
          }
        }
      }

      .document {
        margin-top: 8px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 8px;
      }
    }

    .address {
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;

      .update {
        cursor: pointer;
      }
    }

    .form-divid {
      display: flex;

      .separator {
        width: 20px;
      }
    }

    form.paiment {
      label {
  
      }
  
      .input {
        border-radius: 8px;
        border: 1px solid #ccc;
        padding: 10px;
        font-size: 1em;
        margin-bottom: 10px;
        background-color: white;
      }
    }
  }
}

@media screen and (max-width: 992px) { 
  .sinod {

    .header {
      flex-direction: column;
      padding: 0px;
      padding-top: 20px;

      .identity {
        padding: 20px;
        margin-top: 20px;
        text-align: left;
        width: 100%;
        border-top: 1px solid #029583;
      }
    }

    .page {

      .tarifs {

        .plans {
          flex-direction: column;

          &> * + * {
            margin-top: 20px;
            margin-left: 0px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) { 
  .sinod {
  }
}
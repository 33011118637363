.sinod .header {
  max-width: 1160px;
  width: 100%;
  margin: 0px auto;
  background: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sinod .header .logo {
  width: 300px;
}
.sinod .header .identity {
  text-align: right;
}
.sinod .bar {
  background: #029583;
  height: 45px;
}
.sinod .page {
  max-width: 1160px;
  width: 100%;
  margin: 0px auto;
  background: white;
  padding: 20px;
}
.sinod .page .no-classe {
  text-align: center;
  color: grey;
  padding: 50px 0px;
  font-size: 1.2em;
}
.sinod .page .classe {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.sinod .page .classe .infos .title {
  font-weight: 700;
  margin-bottom: 10px;
}
.sinod .page .classe .infos .category {
  margin-bottom: 10px;
}
.sinod .page .classe .infos .professors {
  margin-bottom: 10px;
}
.sinod .page .process-sinod {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.sinod .page .packages > * + * {
  margin-top: 20px;
}
.sinod .page .packages .package {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.sinod .page .packages .package:hover {
  background: #E9E9E9;
}
.sinod .page .tarifs .plans {
  margin: 20px 0px;
  display: flex;
}
.sinod .page .tarifs .plans > * + * {
  margin-left: 20px;
}
.sinod .page .tarifs .plans .plan {
  flex: 1;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 20px 0px;
}
.sinod .page .tarifs .plans .plan:hover {
  background: #F9F9F9;
  border-color: grey;
}
.sinod .page .tarifs .plans .plan.active {
  border-color: green;
  background-color: #a8ffa8;
}
.sinod .page .tarifs .plans .plan .number {
  margin-top: 10px;
  font-weight: 700;
  font-size: 1.2em;
}
.sinod .page .tarifs .document {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.sinod .page .form-divid {
  display: flex;
}
.sinod .page .form-divid .separator {
  width: 20px;
}
.sinod .page form.paiment .input {
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 1em;
  margin-bottom: 10px;
}
@media screen and (max-width: 992px) {
  .sinod .header {
    flex-direction: column;
    padding: 0px;
    padding-top: 20px;
  }
  .sinod .header .identity {
    padding: 20px;
    margin-top: 20px;
    text-align: left;
    width: 100%;
    border-top: 1px solid #029583;
  }
  .sinod .page .tarifs .plans {
    flex-direction: column;
  }
  .sinod .page .tarifs .plans > * + * {
    margin-top: 20px;
    margin-left: 0px;
  }
}
.head-component {
  display: flex;
  align-items: center;
  padding-bottom: 0px;

  &.sub {
    background: #F9F9F9;

    h1 {
      font-size: 1.6em;
      padding: 4px 0px;
    }
  }

  &.no-tabs {
  }

  .loader-component {
    flex: 1;
    justify-content: flex-start;
    padding: 5px 0px;
  }

  h1 {
    margin: 0px;
    flex: 1;
    background: #4F1D50;
    padding: 30px 20px;
    color: white;

    .prefix {
      color: #FCFCFC;
    }
  }

  .actions {
    display: flex;
    align-items: center;

    &> * + * {
      margin-left: 20px;
    }

    form {
      display: flex;
      align-items: center;

      &> * + * {
        margin-left: 20px;
      }
    }
  }
}
.etipi {
  font-family: "Source Sans Pro", sans-serif !important;
  color: #fff;
  background-color: #191919;
  min-height: 100vh;
}
.etipi button, .etipi a.btn {
  align-self: center;
  background-color: #f0c990;
  border: 1px solid #f0c990;
  border-radius: 100em;
  color: white;
  cursor: pointer;
  font-weight: bold;
  margin-top: 1.9em;
  padding: 0.55rem 2.5rem;
  text-transform: uppercase;
  transition: 0.2s;
  width: 100%;
  white-space: normal;
  margin: 2em 0;
  width: auto;
}
.etipi button:hover, .etipi a.btn:hover {
  background-color: white;
  color: #f0c990;
}
.etipi .header {
  max-width: 1160px;
  width: 100%;
  margin: 0px auto;
  padding: 0px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.etipi .header .logo {
  width: 300px;
}
.etipi .header .identity {
  text-align: right;
}
.etipi .bar {
  background: #d29e3a;
  height: 45px;
}
.etipi h3 {
  display: flex;
  align-items: center;
  gap: 12px;
  color: white;
}
.etipi h3 .bullet {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d29e3a;
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  width: 35px;
  height: 35px;
}
.etipi .page {
  max-width: 1160px;
  width: 100%;
  margin: 0px auto;
  padding: 20px 50px;
}
.etipi .page .no-classe {
  text-align: center;
  color: grey;
  padding: 50px 0px;
  font-size: 1.2em;
}
.etipi .page .classe {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.etipi .page .classe .infos .title {
  font-weight: 700;
}
.etipi .page .classe .infos .category {
  margin-bottom: 10px;
}
.etipi .page .classe .infos .professors {
  margin-bottom: 10px;
}
.etipi .page .sessions {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}
.etipi .page .sessions > * + * {
  border-top: 1px solid #ccc;
}
.etipi .page .sessions .session {
  padding: 20px;
  cursor: pointer;
}
.etipi .page .sessions .session:hover {
  background: #F9F9F9;
}
.etipi .page .sessions .session.active {
  border-color: #d29e3a;
  background-color: #efd6a7;
  color: black;
}
.etipi .page .packages > * + * {
  margin-top: 20px;
}
.etipi .page .packages .package {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.etipi .page .packages .package:hover {
  background: #494949;
}
.etipi .page .tarifs .plans {
  margin: 20px 0px;
  display: flex;
}
.etipi .page .tarifs .plans > * + * {
  margin-left: 20px;
}
.etipi .page .tarifs .plans .plan {
  flex: 1;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 20px 0px;
}
.etipi .page .tarifs .plans .plan:hover {
  background: #F9F9F9;
  border-color: grey;
  color: black;
}
.etipi .page .tarifs .plans .plan.active {
  border-color: #d29e3a;
  background-color: #efd6a7;
  color: black;
}
.etipi .page .tarifs .plans .plan.active:hover {
  color: black;
}
.etipi .page .tarifs .plans .plan .number {
  margin-top: 10px;
  font-weight: 700;
  font-size: 1.2em;
}
.etipi .page .tarifs .document {
  margin-top: 8px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.etipi .page .address {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}
.etipi .page .address .update {
  cursor: pointer;
}
.etipi .page .form-divid {
  display: flex;
}
.etipi .page .form-divid .separator {
  width: 20px;
}
.etipi .page form.paiment .input {
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 1em;
  margin-bottom: 10px;
  background-color: white;
}

@media screen and (max-width: 992px) {
  .sinod .header {
    flex-direction: column;
    padding: 0px;
    padding-top: 20px;
  }
  .sinod .header .identity {
    padding: 20px;
    margin-top: 20px;
    text-align: left;
    width: 100%;
    border-top: 1px solid #029583;
  }
  .sinod .page .tarifs .plans {
    flex-direction: column;
  }
  .sinod .page .tarifs .plans > * + * {
    margin-top: 20px;
    margin-left: 0px;
  }
}
*, *::before, *::after {
  box-sizing: border-box;
}

a, button {
  color: #007bff;
  text-decoration: none;

  &.center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &.ressource {
    padding: 10px;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #ccc;
    background: white;
    display: block;
    position: relative;

    &:hover {
      background: #E9E9E9;
    }

    &::after {
      content: '>';
      position: absolute;
      top: 4px;
      right: 10px;
      font-size: 1.4em;
      font-weight: 700;
      color: black;
    }
  }

  &.btn {
    color: white;
    font-weight: 500;
    font-size: 1em;
    cursor: pointer;
    background: none;
    border: none;
    padding: 10px;
    border-radius: 8px;
    outline: none;
    background: #520b4e;
    color: white;

    &:hover {
      background-color: #863781;
    }

    &.center {
      margin: 0px auto;
    }

    &.clear {
      background: white;
      font-weight: 500;
      padding: 5px;

      &:hover {
        background-color: #ceddff;
      }
    }

    &.grey {
      background: none;
      color: grey;
      font-size: 0.8em;
      padding: 5px 10px;

      &:hover {
        color: #007bff;
        background-color: #F9F9F9;
      }
    }

    &.light {
      background: white;
      font-weight: 500;
      padding: 5px 0px;
      color: blue;

      &:hover {
        color: #0b5ed7;
      }
    }

    &.red {
      color: #df3e3e;
      background: #ffcece;
    }

    &.small {
      padding: 5px;
    }

    &.full {
      display: block;
      width: 100%;
      text-align: center;
    }

    &:disabled {
      color: grey;
      cursor: not-allowed;
      background: #D9D9D9;
    }
  }
}

.list-page {
  .filters {
    margin: 40px;
    margin-bottom: 0px;
    display: flex;

    &> * + * {
      margin-left: 20px;
    }

    .filter {
      
      &.full {
        flex: 1;
      }
    }
  }
}


h3 {
  color: #4F1D50;
}

.fields-table {

  .field-component {
    margin: 0px;
    padding: 20px;
  }

  &>*:nth-child(even) {
    background: #F4F4F4;
  }
  &>*:nth-child(odd) {
    background: #EDEDED;
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    display: flex;
    flex-direction: column;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
}

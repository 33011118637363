.pay-page {
  width: 100%;
  height: 100vh;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #D9D9D9;

  .wrapper {
    background: white;
    border-radius: 8px;
    padding: 20px;
    max-width: 450px;
  }

  .head {
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;

    .plateform {
      display: flex;
      align-items: center;
      flex: 1;

      img {
        margin-right: 40px;
        width: 100px;
      }
  
      .name {
        flex: 1;
        font-size: 1.8em;
  
        .sub {
          font-size: 0.8em;
        }
      }
    }

    .identity {
      flex: 0 0 auto;
      text-align: right;
    }
  }



  .link {
    text-transform: none;
    display: inline-block;
    color: #fff;
    background-color: #4e0755;
    padding: 4px .9em 4px 1.2em;
    margin: 4px 0;
    border: none;
    font-weight: 400;
    border-radius: 27px;
    text-decoration: none;
    font-size: 16px;
    display: block;
    line-height: 22.8571px;
    cursor: pointer;
  }
  form {

    .box {
      display: flex;

      &> * + * {
        margin-left: 20px;
      }

      &> * {
        flex: 1;
      }
    }

    label {

    }

    .input {
      border-radius: 8px;
      border: 1px solid #ccc;
      padding: 10px;
      font-size: 1em;
      margin-bottom: 10px;
    }

    button {
      width: 100%;
      margin-top: 10px;
    }
  }

}

@media screen and (max-width: 768px) { 
  .pay-page {
    background: white;
    display: block;
    .head {
      flex-direction: column;
      align-items: inherit;
      flex-wrap: wrap;
      padding: 0px;

      .plateform {
        padding: 20px;

        .name {
          flex: auto;
        }
  
        img {
          width: 50px;
        }
      }

      .identity {
        padding: 20px;
        padding-top: 20px;
        text-align: left;
        border-top: 1px solid #ccc;
      }
    }

    .message {
      padding: 20px;
    }

    .threetime {
      flex-direction: column;
    }

    .page {
      flex-direction: column-reverse;

      .content {
        flex: 1;
        padding: 0px 20px;
        padding-bottom: 50px;
      }

      .prices {
        width: inherit;
        margin: 0px;
        background: #D9D9D9;
        padding: 0px 20px;
      }

      .form-divid {
        flex-direction: column;

        .separator {
          height: 20px;
        }
      }
    }
  }
}
form.register {
  width: 100%;
  max-width: 350px;
  margin: 0px;
}
form.register img {
  margin: 20px 0px;
}
form.register .select-component {
  margin-bottom: 10px;
}
form.register input {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  margin-bottom: 10px;
  font-size: 1.2em;
  outline: none;
}
form.register input:focus {
  border-color: #5dd0f3;
}
form.activate {
  width: 100%;
  max-width: 350px;
  margin: 0px;
  background: white;
  border-radius: 4px;
  padding: 20px;

  &>svg {
    width: 160px;
    margin: 40px auto;
    display: block;
  }

  input {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 8px;
    width: 100%;
    margin-bottom: 10px;
    font-size: 1.2em;
    outline: none;

    &:focus {
      border-color: #5dd0f3;
    }
  }

  a:not(.btn) {
    text-decoration: none;
    display: block;
    text-align: center;
    cursor: pointer;
    background: white;
    border-radius: 3px;
    padding: 10px 0px;
    font-size: 0.9em;

    &.forgot-password {
      text-align: right;
      padding: 0px;
      padding-right: 5px;
      margin-bottom: 20px;
      margin-top: -5px;

      &:hover {
        background: none;
      }
    }

    &:hover {
      background: #daf7ff;
    }
  }
}
.user-layout {
  max-height: 100vh;
  display: flex;
  flex: 1;
  min-height: 100vh;
}
.user-layout .ressource-page {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.user-layout .ressource-page > .loader-component {
  padding: 20px 0px;
}
.user-layout .ressource-page .content {
  padding: 20px;
}
.user-layout .ressource-page .content.small {
  max-width: 770px;
}
.user-layout .ressource-page .content .field {
  display: flex;
  margin-bottom: 20px;
}
.user-layout .ressource-page .content .field.box .key {
  margin-top: 0px;
}
.user-layout .ressource-page .content .field .key {
  width: 200px;
  font-weight: bold;
  text-align: right;
  margin-right: 20px;
  margin-top: 10px;
}
.user-layout .ressource-page .content .field .value {
  flex: 1;
}
.user-layout .ressource-page .content .field .value > * + * {
  margin-top: 10px;
}
.user-layout .ressource-page .content .field .value .line {
  display: flex;
}
.user-layout .ressource-page .content .field .value .line > * + * {
  margin-left: 20px;
}
.user-layout .ressource-page .content .field .value .line > * {
  flex: 1;
}
.user-layout > .wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  transform: translate(0px, 0px);
  transition-duration: 500ms;
  background: white;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.3) 6px 6px 16px 0px;
}
.user-layout > .wrapper .corp {
  flex: 1;
  display: flex;
}
.user-layout > .wrapper .corp .menu {
  font-size: 1.2em;
  display: flex;
  flex-direction: column;
  width: 120px;
  border-right: 1px solid #ccc;
  box-shadow: 0px 2px 0px #D9D9D9;
  z-index: 10;
  max-height: 100vh;
  transition-duration: 300ms;
}
.user-layout > .wrapper .corp .menu .logo {
  margin: 15px;
  text-align: center;
}
.user-layout > .wrapper .corp .menu .logo img {
  max-width: 80%;
  width: 80%;
}
.user-layout > .wrapper .corp .menu .pages {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 50px;
}
.user-layout > .wrapper .corp .menu .pages > * + * {
  margin-top: 10px;
}
.user-layout > .wrapper .corp .menu .pages .wrapper {
  display: flex;
  flex: 1;
}
.user-layout > .wrapper .corp .menu .pages .wrapper a {
  width: 100%;
  font-size: 0.8em;
  margin: 0px;
  padding: 0px 0px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 8px;
  margin: 10px 5px;
  height: 69px;
  color: #520b4e;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.user-layout > .wrapper .corp .menu .pages .wrapper a.disabled {
  color: grey;
  cursor: not-allowed;
}
.user-layout > .wrapper .corp .menu .pages .wrapper a.active {
  position: relative;
}
.user-layout > .wrapper .corp .menu .pages .wrapper a.active::after {
  z-index: -1;
  content: "";
  right: -6px;
  height: 100%;
  width: 6px;
  border-radius: 4px 0px 0px 4px;
  position: absolute;
  top: 0px;
  background: #520b4e;
}
.user-layout > .wrapper .corp .menu .pages .wrapper a svg {
  fill: #520b4e;
  width: 45px;
  height: 45px;
  margin-bottom: 5px;
}
.user-layout > .wrapper .corp .menu .cta-mobile {
  display: none;
}
.user-layout > .wrapper .corp .menu .cta {
  border: none;
  font-size: 1em;
  background: none;
  cursor: pointer;
  margin: 15px;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  position: relative;
}
.user-layout > .wrapper .corp .menu .cta:hover {
  background-color: #E9E9E9;
}
.user-layout > .wrapper .corp .menu .cta svg {
  fill: #520b4e;
  width: 25px;
  height: 25px;
}
.user-layout > .wrapper .corp .menu .cta .tools {
  position: absolute;
  left: 100%;
  bottom: 6px;
  background: white;
  border-radius: 8px;
  padding: 5px 0px;
  max-width: 150px;
  width: 100vw;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 32px 0px;
}
.user-layout > .wrapper .corp .menu .cta .tools button {
  width: 100%;
  padding: 5px;
  cursor: pointer;
  background: white;
  border: none;
  font-size: 14px;
}
.user-layout > .wrapper .corp .menu .cta .tools button:hover {
  background: #E9E9E9;
}
.user-layout > .wrapper .corp .page {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: white;
  max-height: 100vh;
  overflow: auto;
  transition-duration: 300ms;
}

@media screen and (max-width: 650px) {
  .user-layout .wrapper {
    position: relative;
  }
  .user-layout .wrapper .corp {
    flex-direction: column;
  }
  .user-layout .wrapper .corp .menu {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    border: none;
    position: relative;
    box-shadow: none;
  }
  .user-layout .wrapper .corp .menu.show .pages {
    display: block;
  }
  .user-layout .wrapper .corp .menu .cta-mobile {
    display: block;
    margin-left: 20px;
    cursor: pointer;
  }
  .user-layout .wrapper .corp .menu .cta-mobile svg {
    width: 20px;
  }
  .user-layout .wrapper .corp .menu .logo img {
    width: 20px;
    max-width: inherit;
  }
  .user-layout .wrapper .corp .menu .pages {
    position: absolute;
    background: white;
    display: none;
    z-index: 9999;
    top: 100%;
    left: 0px;
    padding: 20px 0px;
    width: 100%;
    margin: 0px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 32px 0px;
  }
  .user-layout .wrapper .corp .menu .cta {
    padding: 2px;
    margin: 2px;
    margin-right: 20px;
  }
  .user-layout .wrapper .corp .menu .cta .tools {
    left: inherit;
    right: 100%;
    top: -3px;
    bottom: inherit;
  }
  .user-layout .wrapper .corp .page {
    min-height: none;
    max-height: none;
    border: none;
  }
}
@media print {
  body {
    min-height: none;
    max-height: none;
  }
  body .user-layout {
    min-height: none;
    max-height: none;
  }
  body .user-layout .wrapper {
    flex-direction: column-reverse;
    position: relative;
    min-height: none;
    max-height: none;
  }
  body .user-layout .wrapper .corp .menu {
    display: none;
  }
  body .user-layout .wrapper .corp .page {
    min-height: none;
    max-height: none;
  }
}